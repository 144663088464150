import { i18n } from '@/modules/i18n'

const { t: $t } = (i18n.global as any)

/* eslint-disable */
/* eslint-disable no-misleading-character-class */
import { addMinutes, compareAsc, set } from 'date-fns'

export const alpha: { [k: string]: RegExp } = {
  en: /^[A-Z]*$/i,
  cs: /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]*$/i,
  da: /^[A-ZÆØÅ]*$/i,
  de: /^[A-ZÄÖÜß]*$/i,
  es: /^[A-ZÁÉÍÑÓÚÜ]*$/i,
  fr: /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]*$/i,
  it: /^[A-Z\xC0-\xFF]*$/i,
  lt: /^[A-ZĄČĘĖĮŠŲŪŽ]*$/i,
  nl: /^[A-ZÉËÏÓÖÜ]*$/i,
  hu: /^[A-ZÁÉÍÓÖŐÚÜŰ]*$/i,
  pl: /^[A-ZĄĆĘŚŁŃÓŻŹ]*$/i,
  pt: /^[A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ]*$/i,
  ru: /^[А-ЯЁ]*$/i,
  sk: /^[A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ]*$/i,
  sr: /^[A-ZČĆŽŠĐ]*$/i,
  sv: /^[A-ZÅÄÖ]*$/i,
  tr: /^[A-ZÇĞİıÖŞÜ]*$/i,
  uk: /^[А-ЩЬЮЯЄІЇҐ]*$/i,
  ar: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]*$/,
  az: /^[A-ZÇƏĞİıÖŞÜ]*$/i,
  ug: /^[A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ]*$/i,
};

export const alphaSpaces: { [k: string]: RegExp } = {
  en: /^[A-Z\s]*$/i,
  cs: /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ\s]*$/i,
  da: /^[A-ZÆØÅ\s]*$/i,
  de: /^[A-ZÄÖÜß\s]*$/i,
  es: /^[A-ZÁÉÍÑÓÚÜ\s]*$/i,
  fr: /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\s]*$/i,
  it: /^[A-Z\xC0-\xFF\s]*$/i,
  lt: /^[A-ZĄČĘĖĮŠŲŪŽ\s]*$/i,
  nl: /^[A-ZÉËÏÓÖÜ\s]*$/i,
  hu: /^[A-ZÁÉÍÓÖŐÚÜŰ\s]*$/i,
  pl: /^[A-ZĄĆĘŚŁŃÓŻŹ\s]*$/i,
  pt: /^[A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ\s]*$/i,
  ru: /^[А-ЯЁ\s]*$/i,
  sk: /^[A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ\s]*$/i,
  sr: /^[A-ZČĆŽŠĐ\s]*$/i,
  sv: /^[A-ZÅÄÖ\s]*$/i,
  tr: /^[A-ZÇĞİıÖŞÜ\s]*$/i,
  uk: /^[А-ЩЬЮЯЄІЇҐ\s]*$/i,
  ar: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ\s]*$/,
  az: /^[A-ZÇƏĞİıÖŞÜ\s]*$/i,
  ug: /^[A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ\s]*$/i,
};

export const alphanumeric: { [k: string]: RegExp } = {
  en: /^[0-9A-Z]*$/i,
  cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]*$/i,
  da: /^[0-9A-ZÆØÅ]$/i,
  de: /^[0-9A-ZÄÖÜß]*$/i,
  es: /^[0-9A-ZÁÉÍÑÓÚÜ]*$/i,
  fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]*$/i,
  it: /^[0-9A-Z\xC0-\xFF]*$/i,
  lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ]*$/i,
  hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ]*$/i,
  nl: /^[0-9A-ZÉËÏÓÖÜ]*$/i,
  pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ]*$/i,
  pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ]*$/i,
  ru: /^[0-9А-ЯЁ]*$/i,
  sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ]*$/i,
  sr: /^[0-9A-ZČĆŽŠĐ]*$/i,
  sv: /^[0-9A-ZÅÄÖ]*$/i,
  tr: /^[0-9A-ZÇĞİıÖŞÜ]*$/i,
  uk: /^[0-9А-ЩЬЮЯЄІЇҐ]*$/i,
  ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]*$/,
  az: /^[0-9A-ZÇƏĞİıÖŞÜ]*$/i,
  ug: /^[0-9A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ]*$/i,
};

export const alphaDash: { [k: string]: RegExp } = {
  en: /^[0-9A-Z_-]*$/i,
  cs: /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ_-]*$/i,
  da: /^[0-9A-ZÆØÅ_-]*$/i,
  de: /^[0-9A-ZÄÖÜß_-]*$/i,
  es: /^[0-9A-ZÁÉÍÑÓÚÜ_-]*$/i,
  fr: /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ_-]*$/i,
  it: /^[0-9A-Z\xC0-\xFF_-]*$/i,
  lt: /^[0-9A-ZĄČĘĖĮŠŲŪŽ_-]*$/i,
  nl: /^[0-9A-ZÉËÏÓÖÜ_-]*$/i,
  hu: /^[0-9A-ZÁÉÍÓÖŐÚÜŰ_-]*$/i,
  pl: /^[0-9A-ZĄĆĘŚŁŃÓŻŹ_-]*$/i,
  pt: /^[0-9A-ZÃÁÀÂÇÉÊÍÕÓÔÚÜ_-]*$/i,
  ru: /^[0-9А-ЯЁ_-]*$/i,
  sk: /^[0-9A-ZÁÄČĎÉÍĹĽŇÓŔŠŤÚÝŽ_-]*$/i,
  sr: /^[0-9A-ZČĆŽŠĐ_-]*$/i,
  sv: /^[0-9A-ZÅÄÖ_-]*$/i,
  tr: /^[0-9A-ZÇĞİıÖŞÜ_-]*$/i,
  uk: /^[0-9А-ЩЬЮЯЄІЇҐ_-]*$/i,
  ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ_-]*$/,
  az: /^[0-9A-ZÇƏĞİıÖŞÜ_-]*$/i,
  ug: /^[0-9A-Zچۋېرتيۇڭوپھسداەىقكلزشغۈبنمژفگخجۆئ_-]*$/i,
};

export const getLocale = (params?: { locale?: string } | [string | undefined]) => {
  if (!params) {
    return undefined;
  }

  return Array.isArray(params) ? params[0] : params.locale;
};

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

export function getSingleParam<TParam = unknown>(params: [TParam] | Record<string, TParam>, paramName: string) {
  return Array.isArray(params) ? params[0] : params[paramName]
}

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

const required = (value: any) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false || !value) {
    return $t("This field is required")
  }
  return true
}

const email = (value: any) => {
  if (!value || !value.length) {
    return true
  }

  const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!EMAILREG.test(value)) {
    return $t('This field must be a valid email')
  }
  return true
}

const confirmed = (value: any, [target]: string) => {
  if (value === target) {
    return true
  }
  return $t('Passwords must match')
}

const multiplicityСheck = (value: any, [target1, target2]: string|any) => {
  if (isEmpty(value) || isEmpty(target1)) {
    return true
  }
  const V = value.replace(/ /g, '')
  const T1 = target1.replace(/ /g, '')
  if (target2) {
    const T2 = target2.replace(/ /g, '')
    if (T1 % T2 == 0) {
      return true
    }
  } else {
    if (T1 % V == 0) {
      return true
    }
  }
  return $t('The resource must be sold only as a multiple of the minimum lot')
}

const min = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).length >= Number(length)) {
    return String(value).length >= Number(length)
  } else {
    const minErrMessage = $t('Min')
    return `${minErrMessage}: ${length}`
  }
}

const minNoSpaces = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).replace(/\s/g,'').length >= Number(length)) {
    return String(value).replace(/\s/g,'').length >= Number(length)
  } else {
    const minNoSpacesErrMessage = $t('Min value')
    return `${minNoSpacesErrMessage}: ${length}`
  }
}

const maskedPhone = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => min(val, { length }))
  }

  if (String(value).replace(/[^\d]/g,'').length >= Number(length)) {
    return String(value).replace(/[^\d]/g,'').length >= Number(length)
  } else {
    const maskedPhoneErrorMessage = $t('Phone error')
    return `${maskedPhoneErrorMessage}: ${length}`
  }
}

const max = (value: unknown, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const length = getSingleParam(params, 'length')
  if (Array.isArray(value)) {
    return value.every(val => max(val, { length }))
  }

  if (String(value).length <= Number(length)) {
    return String(value).length <= Number(length)
  } else {
    const maxErrMessage = $t('Max')
    return `${maxErrMessage}: ${length}`
  }
}

const alphaValidator = (value: unknown, params: [string | undefined] | { locale?: string }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }
  const locale = getLocale(params)
  if (Array.isArray(value)) {
    return value.every(val => alphaValidator(val, { locale }))
  }

  const valueAsString = String(value);
  // Match at least one locale.
  if (!locale) {
    if (!Object.keys(alpha).some(loc => alpha[loc].test(valueAsString))) {
      return $t('Only alpha characters')
    } else {
      return Object.keys(alpha).some(loc => alpha[loc].test(valueAsString))
    }
  }
  return (alpha[locale] || alpha.en).test(valueAsString)
}

const arrayLengthMax = (value: unknown, params: [number | string] | { length: string | number }) => {
  // Normalize the length value
  const length = getSingleParam(params, 'length')
  if (isNullOrUndefined(value)) {
    return false
  }

  if (!(value as ArrayLike<unknown>).length) {
    value = Array.from(value as ArrayLike<unknown>)
  }

  if ((value as ArrayLike<unknown>).length <= Number(length)) {
    return (value as ArrayLike<unknown>).length <= Number(length)
  } else {
    const arrayLengthMaxErrMessage = $t('Must be max')
    return `${arrayLengthMaxErrMessage}: ${length}`
  }
};

const accountingEPoint = (value: any) => {
  if (!value || !value.length) {
    return true
  }
  const ACCOUNTING_POINT = /^[-/A-Z0-9]{16}$/
  if (!ACCOUNTING_POINT.test(value)) {
    return $t('Accounting Eic point error')
  }
  return true
}

const accountingZPoint = (value: any) => {
  if (!value || !value.length) {
    return true
  }
  const ACCOUNTING_POINT = /^[-/A-Z0-9]{16}$/
  if (!ACCOUNTING_POINT.test(value)) {
    return $t('Accounting Z point error')
  }
  return true
}

const isEPoint = (value: any) => {
  if (!value || !value.length || value.length < 2) {
    return true
  }
  const E_POINT = /^[X]$/
  if (!E_POINT.test(value[2])) {
    return $t('The EIS code is entered in this field')
  }
  return true
}

const isZPoint = (value: any) => {
  if (!value || !value.length || value.length < 2) {
    return true
  }
  const Z_POINT = /^[Z]$/
  if (!Z_POINT.test(value[2])) {
    return $t('The Z code is entered in this field')
  }
  return true
}

const eicCheck = (value: any, [target]: string): boolean | string => {
  if (isEmpty(target)) {
    return true
  }
  
  if (value === target) {
    return true
  }

  return $t('A company can have only one EIC code')
}

const customPassword = (value: any) => {
  if (!value || !value.length) {
    return true
  }
  // const PASSWORD_VALIDATION = /^(?=.*[#?!@$%^&*-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^#?!@$%^&*/-a-zA-Z0-9]).{8,14}$/
  const PASSWORD_VALIDATION = /(?=.*[#?!@$%^&*-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{8,14})(^[、_=\\\[\];'",-/./~`!@#$%^&*()+|?><“:{}A-Za-z0-9]*$)/g
  if (!PASSWORD_VALIDATION.test(value)) {
    return $t('Password error')
  }
  return true
}


const checkArchivedAt = (value: Date, [target1]: string|any): boolean | string => {
  if (isEmpty(value) || isEmpty(target1)) {
    return true
  }
  const setTime = (d: Date, s: number): Date => new Date(set(new Date(d), {
    hours: 23,
    minutes: 59,
    seconds: s,
  }))

  const date = setTime(value, 59)
  const T1 = setTime(target1, 58)

  if (compareAsc(date, T1) >= 0) {
    return $t('The application validity date cannot be equal to or later than the delivery start date')
  }
  return true
}

const customTime = (value: Date, params: [string | number] | { length: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }
  const length = getSingleParam(params, 'length')
  const date = new Date(value)
  const timePlusMinutes = addMinutes(new Date(), Number(length))
  if (compareAsc(date, timePlusMinutes) < 0) {
    return $t('Your proposition cannot be less than 10 minutes')
  }
  return true
}

const maxValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const max = getSingleParam(params, 'max')

  if (Number(value.replace(/ /g, '')) <= Number(max)) {
    return Number(value.replace(/ /g, '')) <= Number(max)
  } else {
    const noSpacesMaxErrorMessage = $t('The entered value must not exceed')
    return `${noSpacesMaxErrorMessage}: ${max}`
  }
}

const minValueValidatorNoSpaces = (value: string, params: [string | number] | { max: string | number }): boolean | string => {
  if (isEmpty(value)) {
    return true
  }

  const min = getSingleParam(params, 'min')

  if (Number(value.replace(/ /g, '')) >= Number(min)) {
    return Number(value.replace(/ /g, '')) >= Number(min)
  } else {
    const noSpacesMinErrorMessage = $t('The entered value must not be less')
    return `${noSpacesMinErrorMessage}: ${min}`
  }
}

export {
  required,
  email,
  confirmed,
  min,
  max,
  alphaValidator,
  arrayLengthMax,
  accountingEPoint,
  accountingZPoint,
  isEPoint,
  isZPoint,
  customPassword,
  minNoSpaces,
  customTime,
  multiplicityСheck,
  maskedPhone,
  maxValueValidatorNoSpaces,
  minValueValidatorNoSpaces,
  eicCheck,
  checkArchivedAt,
}
